/* ==================================================
Copyright year:

Updates copyright year with JS
===================================================*/
var currentYear  = new Date().getFullYear();
$('#copyright').text(currentYear);


/* ==================================================
Mobile nav display toggle:

Toggles animated display of main header nav
on small screens
===================================================*/
var $mainNav = $('.page-header .main-nav');
var $navToggle = $('.nav-toggle');
var $body = $('body');
var navState = 'closed';

$navToggle.click(function(e) {
    e.preventDefault();

    if(navState === 'closed') {
      openNav();
    } else {
      closeNav();
    }
});

function closeNav() {
  $navToggle.removeClass('active');
  $body.removeClass('nav-overlay');
  $mainNav.removeClass('open');
  $navToggle.children('.hamburger').removeClass('active');
  Foundation.Motion.animateOut($mainNav, 'fade-out fast');
  navState = 'closed';
}

function openNav() {
  $navToggle.addClass('active');
  $body.addClass('nav-overlay');
  $mainNav.addClass('open');
  $navToggle.children('.hamburger').addClass('active');
  Foundation.Motion.animateIn($mainNav, 'fade-in fast');
  navState = 'open';
}

/* ==================================================
Active Nav:

Adds the class of 'selected' to the anchor (if any)
that matches the current path.
===================================================*/

const path = location.pathname.substring(1);
if (path) {
  $(`nav a[href$="${path}"]`).parent().addClass('selected');
  $(`nav a[href$="${path}"]`).parents('.dropdown').addClass('selected');
}


/* ==================================================
Mobile Nav Dropdown List:

Toggles animated display of the next nav element
after clicking parent icon
===================================================*/

$('.dropdown .arrow-icon').on('click', function (e) {
  e.preventDefault();
  $(this).toggleClass('open');
  const trigger = $(this).parent().next('.list');

  const $toggleNext = new Foundation.Toggler(trigger, {
    animate: 'fade-in fade-out'
  });
  $(trigger).foundation('toggle');
});


/* ==================================================
Toggle element:

Toggles animated display of element with an id that
matches the 'data-toggle-trigger' of the clicked
element. The default animation is slide in and
slide out.
===================================================*/

$('a[data-toggle-trigger]').click(function (e) {
  e.preventDefault();
  const triggerId = $(this).data('toggle-trigger');
  const toggleItem = $(`#${triggerId}`);

  $(this).toggleClass('open');

  const $toggleElement = new Foundation.Toggler(toggleItem, {
    animate: 'animate-in animate-out',
  });

  toggleItem.foundation('toggle');
});


/* ==================================================
Sticky Nav:

Applies class to sticky nav to add bg color
===================================================*/
var rafTimer;
var lastSectionId = '';
var pageheader = document.querySelector('.page-header');

var scrollItems = $('nav li a').map(function(){
  var item = $($(this).attr("href"));
  if (item.length) { return item; }
});

window.onscroll = function (event) {
  cancelAnimationFrame(rafTimer);
  rafTimer = requestAnimationFrame(scrollAction);
};

function scrollAction() {
  //Sticky nav
  if (window.scrollY > pageheader.scrollHeight) {
    document.body.classList.add('sticky');
  } else {
    document.body.classList.remove('sticky');
  }
  //Nav selected state
  var $fromTop = $(this).scrollTop();
  var currentSection = scrollItems.map(function(){
    if ($(this).offset().top < $fromTop + 100) {
      return this;
    }
  });

  currentSection = currentSection[currentSection.length - 1];
  if(currentSection) {
    var id = currentSection[0].id;

    if(lastSectionId != id) {
      lastSectionId = id;
      $('nav a').removeClass('active');
      $(`nav a[href="#${id}"]`).addClass('active');
    }
  }
}

window.onload = scrollAction();


/* ==================================================
Sliders:

Slick carousel setup and initialization
===================================================*/

// $('.quote-slider').slick({
//   adaptiveHeight: true,
//   pauseOnHover: true,
//   draggable: false,
// });



/* ==================================================
Netlify AJAX Form submission:

Grabs action attribute from form and submits to
Netlify via AJAX, then fades out form and displays
thanks message
===================================================*/
var $formWrap = $('#contact-form-wrap');
var $message = $('#form-thanks');
var $form = $('#contact-form');

if($formWrap.length) {
  $('#contact-form').on("submit", function(event) {
    event.preventDefault();
    $.post($form.attr("action"), $form.serialize()).then(function() {
      $message.removeClass('hide').fadeIn();
      $formWrap.addClass('hide');
    });
  });
}

/* ==================================================
Magnific:

Magnific is used for lightbox style popups.

.image-link is for displaying larger versions of
images from a a link.

.video-link is for displaying videos from a link.
Common providers like Youtube and Vimeo will
stop playing when window is closed.
===================================================*/

// $('.image-link').magnificPopup({ type: 'image' });
// $('.video-link').magnificPopup({ type: 'iframe' });


/* ==================================================
Smooth Scrolling :

Smooth Scrolling Jquery Plugin
===================================================*/

// Select all links with hashes
$('a[href^="#"]').on('click',function (e) {
    e.preventDefault();
    closeNav();
    var target = this.hash;
    var $target = $(target);

    $('html, body').stop().animate({
        'scrollTop': $target.offset().top
    }, 900, 'swing', function () {
        window.location.hash = target;
    });
});

//Utilities
$(function(){
  $('html').removeClass('no-js');
});